import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Map from './views/Map.vue'
import Convai from './views/Convai.vue'
import NotFound from './views/NotFound.vue'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home'
  },
  {
    path: '/map',
    component: Map,
    name: 'map'
  },
  {
    path: '/carte',
    component: Map,
    name: 'carte'
  },
  {
    path: '/convai',
    component: Convai,
    name: 'convai'
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound'
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
