<script>
import { ConvaiClient } from "convai-web-sdk"

export default {
  components: {
  },
  data() {
    return {
      convaiClient: null,
      characterID: 'b3a29824-5678-11ef-921f-42010a7be011',
      characterName: "Jehanne LeBer",
      userText: "",
      npcText: "",
      keyPressed: false,
      isTalking: false,
      finalizedUserText: { current: "" },
      npcTextRef: { current: "" },
      enter: 0,
      analyser: null,
      silence_start: 0,
      silence_delay: 0,
      data: {}
    }
  },
  created(){
    this.convaiClient = new ConvaiClient({
      apiKey: '167129eb9413fc0e40b587bf3fdb501d',
      characterId: this.characterID,
      enableAudio: true,
      // sessionId: this.sessionId
      disableAudioGeneration: false,
    });
    this.convaiClient.setResponseCallback((response) => {
      console.log(response)
      if (response.hasUserQuery()) {
        let transcript = response.getUserQuery();
        let isFinal = transcript.getIsFinal();
        if (isFinal) {
          this.finalizedUserText.current += " " + transcript.getTextData();
          transcript = "";
        }
        if (transcript) {
          this.userText = this.finalizedUserText.current + transcript.getTextData();
        } else {
          this.userText = this.finalizedUserText.current;
        }
      }
      if (response.hasAudioResponse()) {
        let audioResponse = response.getAudioResponse();
        this.npcTextRef.current += " " + audioResponse.getTextData();
        this.npcText = this.npcTextRef.current;
      }
    });
    /*
    this.convaiClient.onAudioPlay(() => {
      this.isTalking = true;
    });
    this.convaiClient.onAudioStop(() => {
      this.isTalking = false;
    });

    const recognition = new webkitSpeechRecognition()
    recognition.continuous = true
    recognition.start()

    recognition.onresult = event => {
      if(this.keyPressed){
        this.endRecord()
      }
      recognition.start()
    }
    recognition.onspeechstart = event => {
      this.startRecord()
    }

    /*
    recognition.onsoundend  = (event) => {
      if(this.keyPressed){
        this.endRecord()
      }
      console.log("Speech has stopped being detected", event);
    }
    */
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        this.detectSilence(stream, 500, -70);
        // do something else with the stream
      }).catch(e=>console.log(e));
  },
  computed: {
  },
  methods: {
    sendText(){
      this.finalizedUserText.current = "";
      this.npcTextRef.current = "";
      this.npcText = "";
      this.convaiClient.sendTextChunk(this.userText);
      this.enter = 0;
    },
    startRecord(){
      this.keyPressed = true;
      this.finalizedUserText.current = "";
      this.npcTextRef.current = "";
      this.UserText = "";
      this.npcText = "";
      this.convaiClient.startAudioChunk();
    },
    endRecord(){
      this.keyPressed = false;
      this.convaiClient.endAudioChunk();
    },
    resetHistory(){
      this.convaiClient.resetSession();
    },
    toggleAudioVolume() {
      convaiClient.toggle();
    },
    detectSilence(stream, silence_delay = 500, min_decibels = -80) {
      var AudioContext = window.AudioContext || window.webkitAudioContext || false
      const ctx = new AudioContext
      // const ctx = new AudioContext()
      this.analyser = ctx.createAnalyser()
      const streamNode = ctx.createMediaStreamSource(stream)
      streamNode.connect(this.analyser)
      this.analyser.minDecibels = min_decibels

      this.data = new Uint8Array(this.analyser.frequencyBinCount) // will hold our data
      this.silence_start = performance.now()
      this.triggered = false // trigger only once per silence event

      this.loop()
    },
    loop(time) {
      requestAnimationFrame(this.loop) // we'll loop every 60th of a second to check
      this.analyser.getByteFrequencyData(this.data) // get current data
      if (this.data.some(v => v)) { // if there is data above the given db limit
        if (this.triggered) {
          this.triggered = false
          this.startRecord()
        }
        this.silence_start = time // set it to now
      }
      if (!this.triggered && time - this.silence_start > this.silence_delay) {
        if(this.keyPressed){
          this.endRecord()
        }
        this.triggered = true
      }
    }
  }
}
</script>

<template>
  <div class="plop">{{ npcText }}</div>
  <div v-if="!isTalking" style="padding: 24px;">
    <div v-if="!keyPressed" class="msger-send-btn" @click="startRecord">Talk</div>
    <div v-else class="msger-send-btn" @click="endRecord">Stop</div>
  </div>

  <form @submit.prevent="sendText" class="msger-inputarea">
    <input
      type="text"
      class="msger-input"
      placeholder="Enter your message..."
      v-model.trim="userText"
    />
    <button type="submit" class="msger-send-btn">Send</button>
  </form>
</template>
