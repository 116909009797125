<script>
/*
To do:
  - test trigger ??
  - fallback if no geolocation
  - full-length image of the ghost before you call
  - clean-up css

<uses-permission android:name="android.permission.ACCESS_COARSE_LOCATION" />
<uses-permission android:name="android.permission.ACCESS_FINE_LOCATION" />
<uses-feature android:name="android.hardware.location.gps" />
*/
import { InworldClient, InworldPacket } from '@inworld/web-core'
import { GoogleMap, Marker, CustomControl } from 'vue3-google-map'
import { v4 as uuidv4 } from 'uuid'
import { Geolocation } from '@capacitor/geolocation'
import mapStyle from '../components/mapStyle.json'
import fullGhostList from '../components/ghostList.json'

import PhoneOutline from "vue-material-design-icons/PhoneOutline.vue"
import PhoneHangupIcon from "vue-material-design-icons/PhoneHangup.vue"
import WindowCloseIcon from "vue-material-design-icons/WindowClose.vue"
import ChatOutline from "vue-material-design-icons/ChatOutline.vue"
import InformationIcon from "vue-material-design-icons/InformationVariant.vue"
import ChatEndIcon from "vue-material-design-icons/ChatRemoveOutline.vue"
import CometChatAvatar from "../components/CometChatAvatar.vue"

import Nebula from "../components/Nebula.vue"

export default {
  components: {
    GoogleMap,
    Marker,
    CustomControl,
    PhoneOutline,
    PhoneHangupIcon,
    WindowCloseIcon,
    ChatOutline,
    ChatEndIcon,
    InformationIcon,
    CometChatAvatar,
    Nebula
  },
  data() {
    return {
      currentGhost: null,
      moreInfo: false,
      connection: null,
      message: '',
      history: [],
      talking: false,
      view: 'control',
      mapStyle: mapStyle,
      gmapKey: import.meta.env.VITE_GOOGLE_API_KEY,
      center: {
        lat: 45.5,
        lng: -73.5756462
      },
      location: {
        lat: 45.5,
        lng: -73.5756462
      },
      watchId: null,
      ghosts: fullGhostList,
      inRange: [],
      user: {
        name: 'Marc',
        range: 20,
        autoCall: false,
        id: uuidv4()
      },
      userLocation: false,
      calling: false,
      chatting: false,
      // ringtone: new Audio('/ringtone.mp3'),
      messages: [],
      message: "",
      waiting: false
    }
  },
  computed: {
    isLandscape() {
      return (window.innerHeight > window.innerWidth)  ? 'mobile' : 'desktop'
    },
    userMarker(){
      let heading = (this.location.heading) ? this.location.heading + 180 : 0
      if(heading > 359) {
        heading = heading - 360
      }
      let marker = {
        position: this.location,
        title: 'you',
        icon: {
          path: "M17.8,8C17.26,5.89 15.61,4.24 13.5,3.7V2H10.5V3.7C8.39,4.24 6.74,5.89 6.2,8H4V11H6.2C6.74,13.11 8.39,14.76 10.5,15.3V22H13.5V15.3C15.61,14.76 17.26,13.11 17.8,11H19.97V8H17.8M12.04,9.53L14.5,11H15.76C15.35,12.03 14.53,12.84 13.5,13.26V12L12.06,9.56L12,9.5L11.94,9.56L10.5,12V13.26C9.47,12.84 8.66,12.03 8.24,11H9.5L11.96,9.53L12,9.5H11.96L9.5,8H8.24C8.65,6.97 9.47,6.16 10.5,5.74V7L11.94,9.44L12,9.5L12.06,9.44L13.5,7V5.74C14.53,6.16 15.35,6.97 15.76,8H14.5L12.04,9.5H12L12.04,9.53Z",
          fillColor: '#005aff',
          fillOpacity: 1,
          rotation: heading,
          strokeWeight: 0
        }
      }
      return marker
    },
    ghostDistance(){
      if(!this.currentGhost){
        return 'N/A'
      } else {
        let d = this.calculateDistance(this.location, this.currentGhost.marker.position)
        return this.formatDistance(d)
      }
    }
  },
  created(){
    if(this.$cookies.isKey('details')){
      this.user = this.$cookies.get('details')
    }
    // this.fetchGhosts()
  },
  mounted(){
  },
  methods: {
    async fetchGhosts() {
      try {
        const response = await fetch('https://ghostr.app/ghosts.php')
        this.ghosts = await response.json()
        console.log(this.ghosts)
      } catch (error) {
        console.error(error)
      }
    },
    async startGeoloc() {
      this.waiting = true
      this.$cookies.set('details', this.user)
      try {
        const coordinates = await Geolocation.getCurrentPosition()
        this.center = {
          lat: coordinates.coords.latitude,
          lng: coordinates.coords.longitude
        }
        this.movePosition(coordinates.coords)
        this.userLocation = true
        if(!this.watchId){
          this.watchPosition()
        }
        console.log('centre', this.center)
      } catch (error) {
        this.userLocation = false
        console.error(error)
      }
      this.view = 'map'
      this.waiting = false
    },
    async watchPosition() {
      this.watchId = await Geolocation.watchPosition({ enableHighAccuracy: true,  maximumAge: 60000 },
        (data) => {
          try {
            this.movePosition(data.coords)
          } catch (e){
            let position = {
              latitude: 45.5,
              longitude: -73.5756462
            }
            this.movePosition(position)
            console.log(err)
          }
        }
      )
    },
    async startSession(){
      this.calling = true
      const client = new InworldClient()
        .setConfiguration({
          capabilities: { emotions: true },
        })
        .setUser({ id: this.user.id, fullName: this.user.name })
        .setScene(import.meta.env.VITE_INWORLD_WORKSPACE + '/characters/' + this.currentGhost.uid)
        .setGenerateSessionToken(this.generateSessionToken)
        .setOnError((err) => console.log(err))
        .setOnHistoryChange((history) => this.history = history)
        .setOnMessage((msg) => console.log(msg))
        .setOnReady(console.log('Ready'))
        .setOnDisconnect(console.log('Disconnected'))

      this.connection = client.build()
      console.log(this.connection)
      this.talking = true
      await this.connection.recorder.start()
      await this.connection.sendTrigger('player-calling')
      this.calling = false
    },
    endSession(){
      this.connection.recorder.stop()
      this.connection.close()
      this.talking = false
    },
    async generateSessionToken(){
      const response = await fetch(import.meta.env.VITE_INWORLD_URL + '/token')
      return response.json()
    },
    async startChat(){
      const client = new InworldClient()
        .setConfiguration({
          capabilities: { emotions: true, audio: false },
        })
        .setUser({ id: this.user.id, fullName: this.user.name })
        .setScene(import.meta.env.VITE_INWORLD_WORKSPACE + '/characters/' + this.currentGhost.uid)
        .setGenerateSessionToken(this.generateSessionToken)
        .setOnError((err) => console.log(err))
        .setOnHistoryChange((history) => this.history = history)
        .setOnMessage((msg) => this.getMessage(msg))
        .setOnReady(console.log('Ready'))
        .setOnDisconnect(console.log('Disconnected'))

      this.connection = client.build()
      await this.connection.sendTrigger('player-calling')
      this.chatting = true
    },
    endChat(){
      this.connection.close()
      this.chatting = false
      this.messages = []
      this.message = ''
    },
    async sendMessage() {
      const sentAt = Date.now()
      this.messages.push({
        text: this.message,
        sender: 'user',
        sentAt: sentAt
      })
      await this.connection.sendText(this.message)
      this.message = ''
    },
    getMessage(packet){
      if(packet.routing.source.isCharacter){
        this.waiting = false
        if(packet.isText()){
          const sentAt = Date.now()
          this.messages.push({
            text: packet.text.text,
            sender: 'ghost',
            sentAt: sentAt
          })
        }
      } else {
        this.waiting = true
      }
    },
    scrollToEnd() {
      const elmnt = this.$refs.container;
      elmnt.scrollTop = elmnt.scrollHeight;
    },
    toReadableString(time) {
      if (time < 0) time = 0;
      let hrs = ~~((time / 3600) % 24),
        mins = ~~((time % 3600) / 60),
        timeType = hrs > 11 ? "PM" : "AM";
      if (hrs > 12) hrs = hrs - 12;
      if (hrs == 0) hrs = 12;
      if (mins < 10) mins = "0" + mins;
      return hrs + ":" + mins + timeType;
    },
    centreMap(){
      this.center = this.location
    },
    async movePosition(coordinates){
      console.log('coordinates',coordinates)
      this.location = {
        lat: coordinates.latitude,
        lng: coordinates.longitude
      }
      if(this.user.autoCall){
        // add accuracy to range
        let range = (coordinates.accuracy < this.user.range) ? coordinates.accuracy + this.user.range : this.user.range
        console.log('the range is ' + range)
        this.inRange = []
        this.ghosts.forEach((item, i) => {
          const d = this.calculateDistance(this.location, item.marker.position)
          this.ghosts[i].distance = d
          if(d < range){
            this.inRange.push(this.ghosts[i])
          }
        })
        this.ghosts.sort((a,b) => a.distance - b.distance)
        if(this.inRange.length){
          this.inRange.sort((a,b) => a.distance - b.distance)
          // call the closest one
          this.callMessage = 'Is calling...'
          if (window.confirm(this.inRange[0].name + " is calling you. Do you accept?")) {
            this.currentGhost = this.inRange[0]
            this.call()
          }
        } else {
          this.currentGhost = null
        }
      }
    },
    calculateDistance(one, two){
      const φ1 = one.lat * Math.PI/180, φ2 = two.lat * Math.PI/180, Δλ = (two.lng - one.lng) * Math.PI/180, R = 6371e3;
      const d = Math.acos( Math.sin(φ1)*Math.sin(φ2) + Math.cos(φ1)*Math.cos(φ2) * Math.cos(Δλ) ) * R
      // d = (d >= 1000) ? Math.floor(d/1000) + ' km' : d + ' m'
      return (typeof d == 'number') ? d : 0
    },
    formatDistance(distance){
      if(typeof distance != 'undefined'){
        if(typeof distance != 'number'){
          console.log(typeof distance)
        }
        if(distance < 1000){
          return distance.toFixed(0) + ' m'
        } else {
          let km = distance/1000
          return km.toFixed(2) + ' km'
        }
      } else {
        console.log(distance, typeof distance)
      }
    },
  }
}
</script>

<template>
  <div v-if="view == 'control'">
    <div style="width: 300px; padding: 2em;">
      <div class="user__box">
        <input type="text" required minlength="3" v-model="user.name" />
        <label>Name</label>
      </div>
      <div class="user__box">
        <label class="checklabel">
          <input name="preferences" type="checkbox" value="1" v-model="user.autoCall" />
          Auto call
        </label>
      </div>
      <div class="user__box" style="margin-top: 20px;" v-if="user.autoCall">
        <input type="number" placeholder="20" v-model="user.range" />
        <label>Maximum distance (meters)</label>
      </div>
      <div class="button" @click="startGeoloc">Start</div>
    </div>
  </div>

  <GoogleMap v-if="view == 'map'" class="mapf" :api-key="gmapKey" style="" :center="center" :zoom="14" :styles="mapStyle" :mapTypeControl="false" :streetViewControl="false" :fullscreenControl="false" ref="theMap">
    <Marker v-if="ghosts.length" v-for="ghost in ghosts" :options="ghost.marker" :key="ghost.uid" @click="currentGhost = ghost"></Marker>
    <Marker v-if="userLocation" :options="userMarker"></Marker>
    <CustomControl position="BOTTOM_LEFT">
      <button class="map-button" @click="centreMap">Centre map</button>
    </CustomControl>
  </GoogleMap>
  <div class="copyright">
    &copy; 2024 <a href="//langue.qc.ca" target="_blank">Au creux de la langue</a>
  </div>
  <div class="overlay" v-if="currentGhost">
    <div id="phone" :class="isLandscape">
      <Nebula></Nebula>
      <div class="rings" v-if="calling">
        <div id="r1" class="ring"></div>
      </div>
      <div class="caller">
        <div class="name">{{ currentGhost.name }}</div>
        <div class="picture">
          <img :src="currentGhost.avatar" :alt="currentGhost.name">
        </div>
        <div class="process" v-html="currentGhost.description"></div>
        <div class="distance" style="color: #ffa500; margin: 1.5em">({{ ghostDistance }} from you)</div>
        <div class="cercle centre" style="background: #1560bd;" title="More information">
          <InformationIcon
            :size="30"
            fillColor="#ffffff"
            class="swipe__icon swipe__icon__heart"
            @click="moreInfo = true"
          />
        </div>
        <div class="actions">
          <div class="cercle float-left" style="background: #018749;" v-if="!talking" title="Call">
            <PhoneOutline
              :size="30"
              fillColor="#ffffff"
              class="swipe__icon swipe__icon__heart"
              @click="startSession"
            />
          </div>
          <div class="cercle float-right" style="background: #960018;" :title="talking ? 'Hang up' : 'Close'">
            <PhoneHangupIcon v-if="talking"
              :size="30"
              fillColor="#ffffff"
              class="swipe__icon swipe__icon__star"
              @click="endSession"
            />
            <WindowCloseIcon v-else
              :size="30"
              fillColor="#ffffff"
              class="swipe__icon swipe__icon__star"
              @click="currentGhost = null"
            />
          </div>
          <div class="cercle centre" style="background: #ffa500;" v-if="!talking">
            <ChatOutline
              :size="30"
              fillColor="#ffffff"
              class="swipe__icon swipe__icon__heart"
              @click="startChat"
            />
          </div>
        </div>
      </div>
      <div class="more-info" v-if="moreInfo">
        <div class="cercle centre" style="background: #1560bd;" title="Close">
          <WindowCloseIcon
            :size="30"
            fillColor="#ffffff"
            class="swipe__icon swipe__icon__star"
            @click="moreInfo = false"
          />
        </div>
      </div>
      <div class="more-info" v-if="chatting">
        <div class="cercle centre" style="background: #ffa500;" title="Close">
          <ChatEndIcon
            :size="30"
            fillColor="#ffffff"
            class="swipe__icon swipe__icon__star"
            @click="endChat"
          />
        </div>
        <div ref="container" class="msger-chat">
          <div class="msg-list" v-for="(message, index) in messages" :key="message.sentAt">
            <div v-if="typeof message.text != 'undefined'">
              <div v-if="message.sender == 'user'" class="msg user-msg">
                <div class="msg--wrapper">
                  <div class="bubble--wrapper">
                    <div class="msg-space" style="padding: 0;">
                      <div class="msg-text">{{ message.text }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="msg ghost-msg">
                <div class="msg--wrapper">
                  <div class="bubble--wrapper">
                    <CometChatAvatar :image="currentGhost.avatar"
                      class="msg-img"
                      style="width: 24px; height: 24px; float: right"
                    />
                    <div class="msg-space" style="float: right; padding: 0; margin-right: 0.5em;">
                      <div class="msg-text" v-html="message.text"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="msg-info-time">
              {{ toReadableString(message.sentAt) }}
            </div>
          </div>
          <div class="spacer"></div>
        </div>
        <form @submit.prevent="sendMessage()">
          <input type="text" placeholder="Enter your message..." v-model.trim="message" style="border: none; width: calc(100% - 1em); margin: 0; padding: .5em;" />
        </form>
      </div>
    </div>
  </div>

  <div v-if="view == 'map' && !currentGhost" style="position: absolute; top: 0; right: 2em; z-index: 10">
    <div class="button" @click="view = 'control'">Set up</div>
  </div>

  <div v-if="waiting" style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; z-index: 20">
    <div class="spinner-pulse">
      <div class="ldio">
        <div></div><div></div><div></div>
      </div>
    </div>
  </div>
</template>
