<script>
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map'
import mapStyle from '../components/newMapStyle.json'
import fullGhostList from '../components/fullGhostList.json'

export default {
  components: {
    GoogleMap,
    Marker,
    InfoWindow
  },
  data() {
    return {
      currentGhost: null,
      mapStyle: mapStyle,
      gmapKey: import.meta.env.VITE_GOOGLE_API_KEY,
      center: {
        lat: 45.5,
        lng: -73.5756462
      },
      location: {
        lat: 45.5,
        lng: -73.5756462
      },
      ghosts: fullGhostList,
      french: (this.$route.name == 'carte')
    }
  },
  created(){
    console.log(this.ghosts)
  },
  methods: {
    closeWIndow(event){
      let buttons = event.target.closest('.gm-style-iw-c').getElementsByTagName('button')
      buttons[0].click()
    }
  }
}
</script>

<template>
  <GoogleMap class="mapf" :api-key="gmapKey" style="" :center="center" :zoom="14" :styles="mapStyle" :mapTypeControl="false" :streetViewControl="false" :fullscreenControl="false" ref="theMap">
    <Marker v-if="ghosts.length" v-for="ghost in ghosts" :options="ghost.marker" :key="ghost.uid" @click="currentGhost = ghost">
      <InfoWindow>
        <div class="ghostlink" :id="'content_' + ghost.uid">
          <span @click="closeWIndow($event)" class="closeWIndow">x</span>
          <a v-if="french" :href="'https://hauntedmontreal.com/fr' + ghost.url" target="_blank">{{ ghost.name_fr }} »</a>
          <a v-else :href="'https://hauntedmontreal.com' + ghost.url" target="_blank">{{ ghost.name }} »</a>
          <div v-if="ghost.en_video.length" style="text-align: center; padding-top: 10px;">
            <a v-if="french" :href="'https://www.youtube.com/watch?v=' + ghost.fr_video" target="_blank" style="color: #aaa; font-weight: 400;">(Capsule vidéo)</a>
            <a v-else :href="'https://www.youtube.com/watch?v=' + ghost.en_video" target="_blank" style="color: #aaa; font-weight: 400;">(Video)</a>
          </div>
        </div>
      </InfoWindow>
    </Marker>
  </GoogleMap>
</template>
