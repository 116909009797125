<script>
// import { Scene, PerspectiveCamera, AmbientLight, WebGLRenderer, FogExp2, TextureLoader, PlaneBufferGeometry, MeshLambertMaterial, Mesh } from 'three'
import { Scene, PerspectiveCamera, AmbientLight, WebGLRenderer, FogExp2, TextureLoader, PlaneBufferGeometry, MeshLambertMaterial, Mesh } from 'https://unpkg.com/three@0.119.1/build/three.module.js';

let scene, camera, renderer, cloudParticles = []

export default {
  mounted () {
    this.renderNebula()
  },
  methods: {
    renderNebula(){
      let width = (window.innerHeight > window.innerWidth)  ? window.innerWidth : window.innerWidth*0.3
      scene = new Scene()
      camera = new PerspectiveCamera(60, width/window.innerHeight, 1, 1000)
      camera.position.z = 1
      camera.rotation.x = 1.16
      camera.rotation.y = -0.12
      camera.rotation.z = 0.27
      const elem = this.$refs.renderer
      let ambient = new AmbientLight(0x555555);
      scene.add(ambient);

      renderer = new WebGLRenderer()
      renderer.setSize(width,window.innerHeight);
      scene.fog = new FogExp2(0x666666, 0.001);
      renderer.setClearColor(scene.fog.color);
      elem.appendChild(renderer.domElement);

      let loader = new TextureLoader()
      let that = this
      loader.load("smoke.png", function(texture){
        let cloudGeo = new PlaneBufferGeometry(500,500)
        let cloudMaterial = new MeshLambertMaterial({
          map:texture,
          transparent: true
        });

        for(let p=0; p<50; p++) {
          let cloud = new Mesh(
            new PlaneBufferGeometry(500,500),
            new MeshLambertMaterial({ map:texture, transparent: true})
          )
          cloud.position.set(
            Math.random()*800 -400,
            500,
            Math.random()*500-500
          );
          cloud.rotation.x = 1.16;
          cloud.rotation.y = -0.12;
          cloud.rotation.z = Math.random()*2*Math.PI;
          cloud.material.opacity = 0.55;
          cloudParticles.push(cloud);
          scene.add(cloud);
        }
      })
      this.render()
    },
    render(){
      cloudParticles.forEach(p => {
        p.rotation.z -=0.001;
      });
      renderer.render(scene, camera)
      requestAnimationFrame(this.render)
    }
  },
  name: "Nebula"
};
</script>

<template>
  <div ref="renderer">
  </div>
</template>
