<template>
  Not Found!
</template>

<script>
// import { CometChatUserListWithMessages } from "../cometchat-pro-vue-ui-kit";
export default {
  name: "notfound",
  components: {
    // CometChatUserListWithMessages,
  },
};
</script>
